<template>
    <a-card title="库存预警">
        <a-table :pagination="false" rowKey="id" :data-source="swState.list" :columns="[
            {title:'ID',dataIndex:'id'},
            {title:'预警类型',dataIndex:'type',slots:{customRender:'type'}},
            {title:'预警时间',dataIndex:'touch_date'},
            {title:'单据编号',dataIndex:'bar_code'},
            {title:'库存物品',dataIndex:'name'},
            {title:'规格型号',dataIndex:'model'},
            {title:'数量',dataIndex:'amount'},
        ]" >
            <template #type="{record}">
                <a-tag color="#0099FF" v-if="record.type === 1">库存上限</a-tag>
                <a-tag color="#FF3366" v-if="record.type === 2">库存下限</a-tag>
                <a-tag color="#FF9900" v-if="record.type === 3">库存质保</a-tag>
            </template>
        </a-table>
        <kd-pager :page-data="swState" :event="getStockWarn"></kd-pager>
    </a-card>
</template>
<script>
import { useStockWarn } from '@/models/addons/storage'
export default {
    setup() {
        let { swState,getStockWarn } = useStockWarn()
        getStockWarn(1,swState.limit)
        return{
            swState,getStockWarn
        }
    },
}
</script>
<style scoped lang="scss">

</style>
